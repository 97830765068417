

.header{
  display: flex;
  justify-content: space-between;
  width: 1000px;
  max-width: 100%;
  padding: 0px 120px;
  height: 10vh;
  box-sizing: border-box; 
  margin: 0 auto;
  align-items: center;

  .icon {
    text-decoration: none;
    width: 66px;
    height: 44px;
    background-image: url('../../assets/icon.png'); 
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat; 
  }

  .navbar {
    display: flex;
    justify-content: center;
    align-items: center;
  
    .navbar-links {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
    
      li {
        margin: 0 1rem;
        position: relative;
      }
    
      a {
        color: rgb(102, 102, 102);
        text-decoration: none;
        font-size: 20px;
      }
    
      a:hover {
        color: #000;
      }
    }
  }

  .todo {
    line-height: 44px;
  }
}






@primary-color: #1DA57A;