.article-list {
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 20px;
 
   ul {
     list-style: none;
     padding: 0;
     margin: 0;
 
     li {
       width: 600px;
       height: 120px;
       margin-bottom: 30px;
       background: #fff;
       box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
       display: flex;
       flex-direction: column;
       justify-content: center;
       padding: 20px;
       border-radius: 8px;
       transition: transform 0.3s ease, box-shadow 0.3s ease;
       
       &:hover {
         transform: translateY(-10px);
         box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
       }
 
       h2 {
         margin: 0 0 10px;
       }
 
       p {
         margin: 0;
       }
     }
   }
 }
 
@primary-color: #1DA57A;