

.page{
   color: rgb(2, 2, 2);
   width: 100%;
   height: 90vh;
   position: relative;

   .content {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%,-50%);
      text-align: center;

      .title{
         font-size: 40px;
      }

      .text {
         font-size: 20px;
      }

      .btn {
         margin-top: 40px;
         display: inline-block;
         width: 200px;
         height: 50px;
         background-color: #000;
         color: #fff;
         text-align: center;
         line-height: 50px; 
         text-decoration: none;
         border-radius: 50px;
         box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px;
      }

      .btn:hover {
         background-color: #fff;
         color: #000;
      }
   }
}

@primary-color: #1DA57A;